import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./public/locales/en/translation.json";
import translationGR from "./public/locales/gr/translation.json";
import translationESP from "./public/locales/es/translation.json";

//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    gr: {
        translation: translationGR,
    },
    es: {
        translation: translationESP,
    },
};
const storedLanguage = localStorage.getItem('selectedLanguage') || 'en';
//i18N Initialization

i18n.use(initReactI18next).init({
    resources,
    lng: storedLanguage,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

// i18n.js

// src/i18n.js

// import i18n from 'i18next';
// import HttpApi from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import { initReactI18next } from 'react-i18next';

// i18n
//   .use(HttpApi)  // Load translations using HTTP
//   .use(LanguageDetector)  // Automatically detect user language
//   .use(initReactI18next)  // Initialize react-i18next
//   .init({
//     fallbackLng: 'en',  // Default language
//     debug: true,  // Enable debugging
//     interpolation: {
//       escapeValue: false,  // React already safes from XSS
//     },
//     backend: {
//       loadPath: '/locales/{{lng}}/{{ns}}.json',  // Path to translation files
//     },
//   });

// export default i18n;
