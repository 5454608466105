import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import ReactLoading from "react-loading";

// Import LandingPage directly
import LandingPage from "./playground/landingPage/landingPage";
import i18n from "../i18n";

// Lazy load other components
const CoursePage = lazy(() => import("./classroomManager"));
const Tilespage = lazy(() => import("./playground/tilespage"));
const GUIrender = lazy(() => import("./playground/render-gui"));
const StudentLogin = lazy(() => import("./playground/studentLogin"));
const TeacherLogin = lazy(() => import("./playground/teacherLogin"));
const ParentLogin = lazy(() => import("./parent/ParentLogin"));
const AdminLogin = lazy(() => import("./admin/AdminLoginpage"));
const Dashboard = lazy(() => import("./admin/pages/dashboard/Dashboard"));
const ParentPayment = lazy(() => import("./parent/Payment"));
const ParentPage = lazy(() => import("./parent/Parent"));
const DemoPage = lazy(() => import("./parent/Demo"));

const App = () => (
    <Router>
        <I18nextProvider i18n={i18n}>
            <Suspense fallback={
                 <div
                 style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                 }}
                 className="w-100 gap-6"
             >
                 <ReactLoading
                     type="spin"
                     color="#000000"
                     height={21}
                     width={21}
                 />
             </div>
            }>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/student" element={<StudentLogin />} />
                    <Route path="/teacher" element={<TeacherLogin />} />
                    <Route path="/courses" element={<CoursePage />} />
                    <Route path="/tiles" element={<Tilespage />} />
                    <Route path="/editor" element={<GUIrender />} />
                    <Route path="/parent" element={<ParentLogin />} />
                    <Route path="/admin" element={<AdminLogin />} />
                    <Route path="/admin-dashboard" element={<Dashboard />} />
                    <Route path="/payment" element={<ParentPayment />} />
                    <Route path="/parent-courses" element={<ParentPage />} />
                    <Route path="/demo" element={<DemoPage />} />
                </Routes>
            </Suspense>
        </I18nextProvider>
    </Router>
);

export default App;
