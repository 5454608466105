import React from "react";
import GUI from "./containers/gui.jsx";
import AppStateHOC from "./lib/app-state-hoc.jsx";
import GuiReducer, {
    guiInitialState,
    guiMiddleware,
    initEmbedded,
    initFullScreen,
    initPlayer,
} from "./reducers/gui";
import LocalesReducer, {
    localesInitialState,
    initLocale,
} from "./reducers/locales";
import { ScratchPaintReducer } from "scratch-paint";
import { setFullScreen, setPlayer } from "./reducers/mode";
import { remixProject } from "./reducers/project-state";
import { setAppElement } from "react-modal";
import totallyNormalStrings from "./lib/l10n.js";
import App from "./App.jsx";
import "bootstrap/dist/css/bootstrap.css";
import "../i18n.js";
import reactDom from "react-dom";

const guiReducers = {
    locales: LocalesReducer,
    scratchGui: GuiReducer,
    scratchPaint: ScratchPaintReducer,
};

export {
    App,
    GUI,
    AppStateHOC,
    setAppElement,
    guiReducers,
    guiInitialState,
    guiMiddleware,
    initEmbedded,
    initPlayer,
    initFullScreen,
    initLocale,
    localesInitialState,
    remixProject,
    setFullScreen,
    setPlayer,
    totallyNormalStrings,
};

reactDom.render(<App />, document.getElementById("app"));
