import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { injectStyle } from "react-toastify/dist/inject-style";
import Stack from "react-bootstrap/Stack";
import background from "../../img/background.png";
import list from "../img/list.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../../img/logo_f2.svg";
import Row from "react-bootstrap/Row";
import cube from "../img/cube-min.svg";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import Helvetica from "../fonts/HelveticaNeue.ttf";
import HelveticaBold from "../fonts/HelveticaNeueBold.ttf";
import HelveticaBlack from "../fonts/HelveticaNeueCondensedBlack.ttf";
// import Montserrat from '../fonts/HelveticaNeueBold.ttf'
import { useTranslation } from "react-i18next";
import "../../../i18n";
import dropdownCaret from "../dropdown-caret.svg";
import languageIcon from "../language-icon.svg";
import { Link } from "react-router-dom";
import kids from "../../playground/img/kidWithLaptop.png";
import shadow from "../../playground/img/BlueShadow.png";
import vector from "../../playground/img/Vector.svg";
// import WhatWeDo from "./WhatWeDo";
import teacher from "../../playground/img/teacher.png";
import parents from "../../playground/img/parents.png";
import students from "../../playground/img/students.png";
import industury from "../../playground/img/Industury.png";
import passion from "../../playground/img/pasion.png";
import mindset from "../../playground/img/mindset.png";
import school from "../../playground/img/school.png";
import commitment from "../../playground/img/commitment.png";
import eng from "../../playground/img/english.png";
import spanish from "../../playground/img/spanish.jpg";
import greek from "../../playground/img/greek.png";
import shadowright from "../../playground/img/shadowright.png";
import backgroundImage from "../../playground/img/background.png";
import {
    fontFamily,
    fontWeight,
    height,
    margin,
    maxHeight,
    textAlign,
} from "@mui/system";
import check from "../../playground/img/check.svg";
import zIndex from "@mui/material/styles/zIndex";

if (typeof window !== "undefined") {
    injectStyle();
}

export default function LandingPage() {
    // console.log("🚀 ~ LandingPage ~ lang:", lang);
    const { t, i18n } = useTranslation();

    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");
    const isShowTerms = params.get("showTerms");
    const isShowPrivacy = params.get("showPrivacy");

    useEffect(() => {
        if (!!i18n && lang) {
            i18n.changeLanguage(lang);
            localStorage.setItem("selectedLanguage", lang);
        }
    }, [i18n, lang]);

    const projectHost = window.location.host.toString().split(":");
    const hostlink = projectHost[1]
        ? window.location.host
        : window.location.hostname;

    const [showCookPolicy, setShowCookiePolicy] = useState(false);
    const [showPrivPolicy, setShowPrivacyPolicy] = useState(false);
    const [showModalContent, setShowModalContent] = useState(false);
    const [loading, showLoading] = useState(false);
    const [modalHeading, setModalHeading] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [show, setShow] = useState(false);
    const [showGame, setShowGame] = useState(false);
    const [gameSelected, setGameSelected] = useState(0);
    const [gameIds, _] = useState([
        "7a16aca9-7567-4b01-9113-a181b82fde2d",
        "0fd8e779-37d8-4269-9ea6-71adc9920e77",
        "e5ef36a4-697d-4705-9767-02d5cf93ee71",
        "45bf76cc-89b3-43aa-927b-3b441fe6d2fb",
        "62ec720b-dc21-4c73-ab8a-df4b7dfbebf3",
        "43af4a57-4c28-4c41-bff8-5b4be9553a91",
    ]);

    const [selectedLanguage, setSelectedLanguage] = useState({
        code: "en",
        label: "English",
        img: eng,
    });

    useEffect(() => {
        if (isShowTerms) {
            showCookiePolicy();
        }
        if (isShowPrivacy) {
            showPrivacyPolicy();
        }
    }, [isShowTerms, isShowPrivacy]);

    const languages = [
        { code: "en", label: "Eng", img: eng },
        { code: "gr", label: "Ελλ", img: greek },
        { code: "es", label: "Esp", img: spanish },
    ];

    useEffect(() => {
        if (!!i18n && lang && languages.some((l) => l.code === lang)) {
            const language = languages.find((l) => l.code === lang);
            setSelectedLanguage(language);
            i18n.changeLanguage(lang);
            localStorage.setItem("selectedLanguage", lang);
        }

        // Dynamically adjust styles based on language
        const isEnglish = i18n.language === "en";
        setResponsiveStyles({
            heroTitleFontSize: isEnglish ? "60px" : "50px",
            heroDescriptionFontSize: "16px",
        });
    }, [i18n, lang]);

    const [responsiveStyles, setResponsiveStyles] = useState({
        heroTitleFontSize: "60px",
        heroDescriptionFontSize: "18px",
    });

    const handleClose = () => {
        setShowCookiePolicy(false);
        setShowPrivacyPolicy(false);
        setShowModalContent(false);
        setShow(false);
        showLoading(false);
    };
    const handleShow = () => setShow(true);

    const handleCloseGame = () => {
        setShowGame(false);
        showLoading(false);
    };

    const showCookiePolicy = () => {
        setModalHeading("Cookie Policy");
        setShowCookiePolicy(true);
        handleShow();
        showLoading(true);
    };

    const showPrivacyPolicy = () => {
        setModalHeading("Privacy Policy");
        setShowPrivacyPolicy(true);
        handleShow();
        showLoading(true);
    };

    const scrollTo = (elementId, atTop) => {
        let section = document.querySelector(`#${elementId}`);
        let position = "center";
        if (atTop) {
            position = "start";
        }
        section.scrollIntoView({ behavior: "smooth", block: `${position}` });
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language.code);
        localStorage.setItem("selectedLanguage", language.code);

        const url = new URL(window.location);
        url.searchParams.set("lang", language.code);
        window.history.pushState({}, "", url);
    };

    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

    // Toggle function for the navbar
    const handleToggle = () => setIsNavbarCollapsed(!isNavbarCollapsed);

    // Function to handle link click and collapse the navbar
    const handleNavLinkClick = (target, shouldCollapse = true) => {
        scrollTo(target);
        if (shouldCollapse) {
            setIsNavbarCollapsed(true);
        }
    };

    const styles = {
        container: {
            marginTop: "80px",
            padding: "20px",
            maxWidth: "1200px",
            margin: "90px  auto  30px",
            //   marginTop:"50px",
            "@media (max-width: 575.98px)": {
                padding: "14px",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                padding: "14px",
            },
        },
        centerAligned: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        mainTitle: {
            fontSize: "48px",
            color: "#142B60",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "800",
            textAlign: "center",
            "@media (max-width: 575.98px)": {
                fontSize: "32px",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                fontSize: "36px",
            },
        },
        mainDescription: {
            fontSize: "16px",
            color: "#142B60",
            fontWeight: "300",
            fontFamily: "'Montserrat', sans-serif",
            maxWidth: "650px",
            textAlign: "center",
            "@media (max-width: 575.98px)": {
                fontSize: "14px",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                fontSize: "15px",
            },
        },
        sectionContainer: (isReverse) => ({
            display: "flex",
            alignItems: "flex-start",
            marginTop: "6rem",
            gap: "40px",
            flexDirection: isReverse ? "row-reverse" : "row",
            flexWrap: "wrap",

            "@media (max-width: 1020px)": {
                marginTop: "2rem",
                justifyContent: "center",
                // alignItems: "center",
                flexDirection: "column",
            },

            "@media (max-width: 575.98px)": {
                marginTop: "2rem",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                marginTop: "3rem",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            },
        }),
        imageContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width: 575.98px)": {
                marginBottom: "20px",
            },
        },
        textContainer: {
            maxWidth: "550px",
            padding: "0px 20px 20px ",
            boxSizing: "border-box",
            flex: "1 1 300px",
            "@media (max-width: 575.98px)": {
                padding: "10px",
                textAlign: "center",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                padding: "15px",
                textAlign: "center",
            },
        },
        image: {
            width: "100%",
            height: "auto",
            objectFit: "cover",
            borderRadius: "6px",
            maxWidth: "100%",
        },
        title: {
            fontSize: "36px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "800",
            color: "#142b60",
            "@media (max-width: 575.98px)": {
                fontSize: "28px",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                fontSize: "30px",
            },
        },
        subtitle: {
            fontSize: "24px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "800",
            color: "#142b60",
            marginTop: "20px",
            marginBottom: "20px",
            "@media (max-width: 575.98px)": {
                fontSize: "18px",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                fontSize: "20px",
            },
        },
        description: {
            fontSize: "16px",
            fontWeight: "300",
            fontFamily: "'Montserrat', sans-serif",
            color: "#142b60",
            "@media (max-width: 575.98px)": {
                fontSize: "14px",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                fontSize: "15px",
            },
        },
    };

    const heroStyles = {
        navbar: {
            marginTop: "0px",
            "@media (max-width: 1020px)": {
                marginTop: "30px",
            },
        },
        container: {
            //   maxWidth: "1500px",
            // backgroundColor: "#142B60",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "#FFF",
            //   paddingTop: "90px",
            //   marginTop: "4.4rem",
            height: "743px",

            "@media (max-width: 1180px)": {
                height: "auto",
                paddingTop: "90px",
            },
        },

        innerContainer: {
            maxWidth: "1200px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            margin: "auto",
            flexWrap: "wrap",
            "@media (min-width: 600px) and (max-width: 1020px)": {
                flexDirection: "column",
                paddingTop: "30px",
            },
        },
        contentContainer: {
            flex: "1 1 50%",
            maxWidth: "665px",
            padding: "20px",
            fontFamily: "'Montserrat', sans-serif",
            marginTop: "15%",
            zIndex: 9,

            "@media (min-width: 600px) and (max-width: 1200px)": {
                padding: "50px  80px 0px 80px",
                maxWidth: "100%",
                textAlign: "left",
                marginTop: "5%",
                marginBottom: "3%",
            },

            "@media (max-width: 575.98px)": {
                flex: "1 1 100%",
                padding: "14px",
                maxWidth: "100%",
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "3%",
            },
        },
        title: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: responsiveStyles.heroTitleFontSize,
            fontWeight: "800",
            color: "#FFF",
            margin: 0,
            "@media (min-width: 600px) and (max-width: 1020px)": {
                fontSize: "70px",
                textAlign: "left",
            },
            "@media (max-width: 575.98px)": {
                fontSize: "36px",
                textAlign: "left",
            },
            "@media (min-width: 576px) and (max-width: 767.98px)": {
                fontSize: "50px",
                textAlign: "left",
            },
        },
        description: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "400",
            fontSize: responsiveStyles.heroDescriptionFontSize,
            lineHeight: "1.5",
            margin: "20px 0",
            "@media (min-width: 600px) and (max-width: 1020px)": {
                fontSize: "18px",
                textAlign: "left",
            },
            "@media (max-width: 575.98px)": {
                fontSize: "16px",
                textAlign: "left",
            },
        },
        list: {
            padding: 0,
            listStyleType: "none",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "400",
            fontSize: responsiveStyles.heroDescriptionFontSize,
            textAlign: "left",
        },
        listItem: {
            display: "flex",
            //   alignItems: "center",
            margin: "10px 0",
        },
        listItemIcon: {
            marginRight: "10px",
            color: "#41C0F0",
        },
        imageContainer: {
            flex: "1 1 50%",
            minWidth: "300px",
            position: "relative",
            display: "none",

            "@media (max-width: 600px)": {
                display: "flex",
                justifyContent: "center",
            },
            "@media (max-width: 1200px) and (min-width: 620px)": {
                display: "block",
            },
        },
        imageContainerImage: {
            display: "flex",
            position: "absolute",
            //   bottom: "0",
            top: "0",
            right: "0",
            justifyContent: "end",

            "@media (max-width: 1200px)": {
                display: "none",
            },
            "@media (max-width: 620px)": {
                display: "none",
            },
        },
        shadowImage: {
            width: "41%",
            height: "auto",
            position: "absolute",
            top: "12px",
            left: "32%",
            transform: "translateX(-30%)",
            zIndex: 2,

            "@media (max-width: 1200px)": {
                width: "55%",
                top: "8px",
                left: "11%",
            },

            "@media (max-width: 620px)": {
                display: "block",
                width: "55%",
                left: "-32%",
                top: "6px",
                transform: "translateX(45%)",
            },
        },

        kidsImage: {
            width: "74.7%",
            height: "auto",
            position: "relative",
            zIndex: 1,
            marginTop: "6.5rem",
            "@media (max-width: 1200px)": {
                width: "100%",
            },
            "@media (max-width: 620px)": {
                display: "block",
                width: "100%",
                left: "0",
                marginTop: "0rem",
            },
        },
    };

    //   const [responsiveStyles, setResponsiveStyles] = useState({});

    useEffect(() => {
        const handleResize = () => {
            setResponsiveStyles(applyResponsiveStyles);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Call handler right away so state is updated with initial window size

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const applyResponsiveStyles = (style) => {
        let finalStyle = { ...style };

        Object.entries(style).forEach(([key, value]) => {
            if (key.startsWith("@media")) {
                const mediaQuery = key.slice(7, -1); // Extract the media query without '()'

                if (window.matchMedia(`(${mediaQuery})`).matches) {
                    finalStyle = { ...finalStyle, ...value };
                }
            }
        });

        return finalStyle;
    };

    return (
        <>
            <link
                href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/5.0.0/mdb.min.css"
                rel="stylesheet"
            />
            <link
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
                rel="stylesheet"
            />
            <link
                href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"
                rel="stylesheet"
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossorigin
            />
            <link
                href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                rel="stylesheet"
            />

            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js"></script>
            <style>
                {`


// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700,900&display=swap');

                .language-icon {
    height: 1.5rem;
    color:#fff;
    vertical-align: middle;
}

.language-caret {
color:#fff;
    margin: 0 .125rem;
}

                    @font-face {
                        font-family: 'Helvetica';
                        src: local('Helvetica'), url(${Helvetica}) format('opentype');
                    }

                    @font-face {
                        font-family: 'Helvetica';
                        font-weight: 900;
                        src: local('Helvetica'), url(${HelveticaBold}) format('opentype');
                    }

                    @font-face {
                        font-family: 'Helvetica';
                        font-weight: 900;
                        src: local('Helvetica'), url(${HelveticaBlack}) format('opentype');
                    }

                    body {
                        font-family: "Montserrat", sans-serif;
                    }

                    .home{
                        height: 100vh;
                        background: url(${background});
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }

                    .animatedLogo{
                        width: 100%;
                        // margin: auto;
                    }

                    .contain{
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        margin: auto;
                    }

                    .navbar-toggler-icon {
                        background-image: url(${list}) !important;
                    }

                    .dropdown-toggle::after {
                        display: none !important;
                    }

                    .nav-link{
                        color: white;
                    }

    //                  .nav-link {
    //     color: #13254C;
    // }



                    .image{
                        margin: auto;
                        width: 14vw;
                        height: 3.8vw;
                        margin-bottom: 0;
                    }

                    .what_we_do{

                    }

                    .contains2{
                        width: 70%;
                        padding: 5vh;
                        margin: auto;
                    }

                    .why_join_us{
                        min-height: 30vh;
                        margin: auto;
                        display: flex;
                        justify-content: center;

                    }

                    .about_us{
                        min-height: 40vh;
                        margin: auto;
                        display: flex;
                        justify-content: center;
                    }

                    .heading{
                        font-weight: bold;
                        font-size: 4vh;
                        color: #014262;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .cube{
                        height: 6vh;
                    }

                    .footer{
                        // background: #1F2023;
                        background: #142B60;
                    }

                    .footer-text{
                        text-align: start;
                        color:#cfc6c6;
                        margin: 1px;
                    }

                        .footer-divider {
                    display: block;
    border: 1px solid #ffffff;
    width: 83%;
    margin: 2em auto 0 auto;
                }

                .footerAddress{
                display: block;
                max-width: 20ch;
                }



                    @media (max-width: 640px) {
                    .footer-divider{
                        display: none;}
                        .footerAddress{
                        // display: none;
                        max-width: 37ch;
                        }
                    }

                    .policy-container{
                        display: flex;
                        flex-direction:row;
                        gap:20px;
                        justify-content: space-evenly;
                        paddingTop:40px;
                    }

                    .contact-us{
                        text-align: start;
                        font-weight: bold;
                        color: white;
                    }

//                     .nav-styles{
//                         // opacity: 0.8;
//                         background: #13254c;
//                          backgroundColor: "#13254c,
//                         width: 100%;
//                         margin: auto;
//                         justify-content: space-around;

// @media only screen and (max-width: 980px) {
//        backgroundColor:"#FFFFFF",
//        color:"#13254C",
//        height:"100%"                  
//        height:"100%"                  

//        height:"100%"

// }


//                     }


.nav-styles {
        background: #13254C;
        width: 100%;
        margin: auto;
        justify-content: space-around;
        height:100vh

@media only screen and (max-width: 980px) {
//        backgroundColor:"#FFFFFF",
//        color:"#13254C",
//        height:"100%"

// }

    }




                    .hexagon {
                        position: relative;
                        width: 100%;
                        height: 75vh;
                        margin: auto;
                        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
                      }

                      .vertex {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 40px;
                        height: 40px;
                        background-color: white;
                        border-radius: 50%;
                        transform: translate(-20px, -20px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        color: #333;
                        border-radius: 50%;
                        border: 5px solid #014262;
                        border-bottom: 1px solid #014262;
                      }

                      .vertex:nth-of-type(1) {
                        top: 18%;
                        left: 50%;
                      }
                      .vertex:nth-of-type(2) {
                        top: 38%;
                        left: 89%;
                      }
                      .vertex:nth-of-type(3) {
                        top: 66%;
                        left: 89%;
                      }
                      .vertex:nth-of-type(4) {
                        top: 86%;
                        left: 50%;
                      }
                      .vertex:nth-of-type(5) {
                        top: 66%;
                        left: 10%;
                      }
                      .vertex:nth-of-type(6) {
                        top: 38%;
                        left: 10%;
                      }

                      .border{
                        background-color: black;
                      }

                      .game{
                        cursor: pointer;
                        height: 0;
                        padding-bottom: 100%;
                        width: 100%;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                      }

                      .game-container{
                        display: flex;

                      }

                      .game-block1{
                        background: red;
                      }

                      .game-block2{
                        background: red;
                      }

                      .game-block3{
                        background: red;
                      }

                    @media only screen and (max-width: 992px) {
                        .contain{
                            width: 100%;
                        }

                        .image{
                            height: 6vh;
                            width: auto;
                        }

                        .footer-text{
                            text-align: center;
                        }

                        .contact-us{
                            text-align: center;
                        }

                        .imf{
                            align-items: center;
                            display: flex;
                            height: 4vh
                        }

                        .contains2{
                            width: 90%;
                        }

                    }

                    @media only screen and (max-width: 600px) {
                        .nav-styles{
                            width: 100%;
                             backgroundColor: "#FFF",
                        }

                        .contains2{
                            width: 95%;

                        }
                    }

@media only screen and (max-width: 980px) {
.nav-styles{
                          backgroundColor:"#FFFFFF",
       color:"#13254C",
       height:"100%"
                        }


}


                   .hero_content{
        margin-top:20px;
        color:#13254C;
    }
                    .hero_content h2{

                    }


 @media only screen and (max-width: 575.98px) {
        .image {
            height: 6vh;
            width: auto;
        }
        .footer-text {
            text-align: center;
        }
        .contact-us {
            text-align: center;
        }
        .imf {
            align-items: center;
            display: flex;
            height: 4vh;
        }
    }

    @media only screen and (max-width: 600px) {
        .nav-styles {
            width: 100%;
        }
    }

    @media only screen and (max-width: 980px) {
        .nav-styles {
            background-color: #FFF;
            color: #13254C;
            height: 100%;
        }
    }
                `}
            </style>

            <div>
                <Stack style={{ width: "100%" }}>
                    <div id="home">
                        <Navbar
                            style={{
                                backgroundColor: "#13254C",
                                height: "auto",
                                padding: "20px 20px 20px 20px",
                            }}
                            fixed="top"
                            variant="light"
                            expand="xl"
                            className="nav-styles"
                            expanded={!isNavbarCollapsed} // Control the expanded state
                        >
                            <Container
                                style={{
                                    margin: "auto",
                                    maxWidth: "1200px",
                                    display: "flex",
                                    gap: "30px",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Navbar.Brand href="#home">
                                    <img
                                        src={logo}
                                        style={{
                                            width: "200px",
                                            height: "auto",
                                        }}
                                        alt="Logo"
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle
                                    aria-controls="basic-navbar-nav"
                                    style={{ outline: "none" }}
                                    onClick={handleToggle}
                                />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav
                                        className="me-auto"
                                        style={{ display: "flex", gap: "20px" }}
                                    >
                                        <Nav.Link
                                            style={{
                                                color: "#FFFFFF",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                                fontWeight: "300",
                                            }}
                                            onClick={() =>
                                                handleNavLinkClick("home")
                                            }
                                        >
                                            {t("home")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{
                                                color: "#FFFFFF",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                                fontWeight: "300",
                                            }}
                                            onClick={() =>
                                                handleNavLinkClick("what_we_do")
                                            }
                                        >
                                            {t("whatWeDo")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{
                                                color: "#FFFFFF",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                                fontWeight: "300",
                                            }}
                                            onClick={() =>
                                                handleNavLinkClick(
                                                    "why_join_us",
                                                    true
                                                )
                                            }
                                        >
                                            {t("whyJoinUs")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{
                                                color: "#FFFFFF",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                                fontWeight: "300",
                                            }}
                                            onClick={() =>
                                                handleNavLinkClick("about_us")
                                            }
                                        >
                                            {t("aboutUs")}
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{
                                                color: "#FFFFFF",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                                fontWeight: "300",
                                            }}
                                            href={"/payment"}
                                        >
                                            {t("joinUs")}
                                        </Nav.Link>
                                    </Nav>
                                    <div
                                        style={applyResponsiveStyles(
                                            heroStyles.navbar
                                        )}
                                        className="d-flex align-items-center"
                                    >
                                        <Dropdown style={{ marginRight: 15 }}>
                                            <Dropdown.Toggle
                                                style={{
                                                    fontFamily:
                                                        "'Montserrat', sans-serif",
                                                    fontWeight: "bold",
                                                    padding: "15px 30px",
                                                    backgroundColor: "#2a3b5e",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    borderRadius: "8px",
                                                    color: "#FFF",
                                                    border: "none",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                }}
                                                id="dropdown-basic"
                                            >
                                                <img
                                                    src={selectedLanguage.img}
                                                    alt=""
                                                    style={{
                                                        width: "30px",
                                                        height: "20px",
                                                    }}
                                                />
                                                {selectedLanguage.label}
                                                <img
                                                    className="language-caret"
                                                    src={dropdownCaret}
                                                    alt="Caret"
                                                />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {languages.map(
                                                    (language, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            onClick={() =>
                                                                handleLanguageChange(
                                                                    language
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    language.img
                                                                }
                                                                alt=""
                                                                style={{
                                                                    width: "30px",
                                                                    height: "20px",
                                                                    marginRight:
                                                                        "8px",
                                                                }}
                                                            />
                                                            {language.label}
                                                        </Dropdown.Item>
                                                    )
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="success"
                                                style={{
                                                    fontFamily:
                                                        "'Montserrat', sans-serif",
                                                    fontWeight: "bold",
                                                    padding: "15px 30px",
                                                    backgroundColor: "#03B4E6",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    borderRadius: "8px",
                                                    color: "#FFF",
                                                    border: "none",
                                                }}
                                                id="dropdown-basic"
                                            >
                                                {t("login")}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to="/admin"
                                                >
                                                    {t("admin")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to="/parent"
                                                >
                                                    {t("parent")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to="/student"
                                                >
                                                    {t("student")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to="/teacher"
                                                >
                                                    {t("teacher")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                        {/* <div className="contain">
                            <img className="animatedLogo" src={logo} />
                            <div className="hero_content">
                                <h2>{t("heroTitle")}</h2>
                                <p>{t("heroDescription1")}</p>
                                <ul>
                                    <li>{t("heroSkill1")}</li>
                                    <li>{t("heroSkill2")}</li>
                                    <li>{t("heroSkill3")}</li>
                                </ul>
                                <p>{t("heroQuote")}</p>
                            </div>
                        </div> */}

                        <div
                            style={applyResponsiveStyles(heroStyles.container)}
                        >
                            <div
                                style={{
                                    maxWidth: "1500px",
                                    margin: "auto",
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={applyResponsiveStyles(
                                        heroStyles.innerContainer
                                    )}
                                >
                                    <div
                                        style={applyResponsiveStyles(
                                            heroStyles.contentContainer
                                        )}
                                    >
                                        <h1
                                            style={applyResponsiveStyles(
                                                heroStyles.title
                                            )}
                                        >
                                            {t("title")}
                                        </h1>
                                        <p
                                            style={applyResponsiveStyles(
                                                heroStyles.description
                                            )}
                                        >
                                            {t("paragraph1")}
                                        </p>
                                        <ul
                                            style={applyResponsiveStyles(
                                                heroStyles.list
                                            )}
                                        >
                                            <li
                                                style={applyResponsiveStyles(
                                                    heroStyles.listItem
                                                )}
                                            >
                                                <span
                                                    style={applyResponsiveStyles(
                                                        heroStyles.listItemIcon
                                                    )}
                                                >
                                                    <img src={check} alt="" />
                                                </span>
                                                <span>{t("bullet1")}</span>
                                            </li>
                                            <li
                                                style={applyResponsiveStyles(
                                                    heroStyles.listItem
                                                )}
                                            >
                                                <span
                                                    style={applyResponsiveStyles(
                                                        heroStyles.listItemIcon
                                                    )}
                                                >
                                                    <img src={check} alt="" />
                                                </span>
                                                <span>{t("bullet2")}</span>
                                            </li>
                                            <li
                                                style={applyResponsiveStyles(
                                                    heroStyles.listItem
                                                )}
                                            >
                                                <span
                                                    style={applyResponsiveStyles(
                                                        heroStyles.listItemIcon
                                                    )}
                                                >
                                                    <img src={check} alt="" />
                                                </span>
                                                <span>{t("bullet3")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        style={applyResponsiveStyles(
                                            heroStyles.imageContainer
                                        )}
                                    >
                                        {/* <img
                                            src={shadow}
                                            alt=""
                                            style={applyResponsiveStyles(
                                                heroStyles.shadowImage
                                            )}
                                        /> */}
                                        <img
                                            src={kids}
                                            alt=""
                                            style={applyResponsiveStyles(
                                                heroStyles.kidsImage
                                            )}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={applyResponsiveStyles(
                                        heroStyles.imageContainerImage
                                    )}
                                >
                                    {/* <img
                                        src={shadow}
                                        alt=""
                                        style={applyResponsiveStyles(
                                            heroStyles.shadowImage
                                        )}
                                    /> */}
                                    <img
                                        src={kids}
                                        alt=""
                                        style={applyResponsiveStyles(
                                            heroStyles.kidsImage
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="what_we_do" id="what_we_do">
                        <div className="contains2" style={{ width: "80%" }}>
                            <Row className="justify-content-md-center">
                                <Col sm={12} style={{ margin: "auto" }}>
                                    <div
                                        style={{
                                            maxWidth: 500,
                                            margin: "auto",
                                            marginTop: 30,
                                            marginBottom: 20,
                                        }}
                                    >
                                        <h2 className="heading">
                                            {t("whatWeDo")}
                                        </h2>
                                        {t("whatWeDoDescription")}
                                    </div>
                                </Col>
                            </Row>
                            <Container className="mt-4">
                                <h2 className="mb-4">{t("teachersTitle")}</h2>
                                <Row className="gy-4">
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t(
                                                        "teachersFullDescription1",
                                                    )}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "teachersFullDescription1Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t(
                                                        "teachersFullDescription2",
                                                    )}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "teachersFullDescription2Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t(
                                                        "teachersFullDescription3",
                                                    )}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "teachersFullDescription3Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                                <h2 className="my-4">{t("parentsTitle")}</h2>
                                <Row className="gy-4">
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t(
                                                        "parentsFullDescription1",
                                                    )}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "parentsFullDescription1Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t(
                                                        "parentsFullDescription2",
                                                    )}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "parentsFullDescription2Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t(
                                                        "parentsFullDescription3",
                                                    )}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "parentsFullDescription3Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                                <h2 className="my-4">{t("kidsTitle")}</h2>
                                <Row className="gy-4">
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t("kidsFullDescription1")}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "kidsFullDescription1Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t("kidsFullDescription2")}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "kidsFullDescription2Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>
                                                    {t("kidsFullDescription3")}
                                                </Card.Title>
                                                <Card.Text className="flex-grow-1">
                                                    {t(
                                                        "kidsFullDescription3Text",
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>   */}
                    <div
                        id="what_we_do"
                        style={applyResponsiveStyles(styles.container)}
                    >
                        <div
                            style={applyResponsiveStyles(styles.centerAligned)}
                        >
                            <h2 style={applyResponsiveStyles(styles.mainTitle)}>
                                {t("whatWeDo")}
                            </h2>
                            <p
                                style={applyResponsiveStyles(
                                    styles.mainDescription
                                )}
                            >
                                {t("whatWeDoDescription")}
                            </p>
                        </div>

                        <div
                            style={applyResponsiveStyles(
                                styles.sectionContainer(false)
                            )}
                        >
                            <div
                                style={applyResponsiveStyles(
                                    styles.imageContainer
                                )}
                            >
                                <img
                                    src={teacher}
                                    alt=""
                                    style={applyResponsiveStyles(styles.image)}
                                />
                            </div>
                            <div
                                style={applyResponsiveStyles(
                                    styles.textContainer
                                )}
                            >
                                <h1 style={applyResponsiveStyles(styles.title)}>
                                    {t("teachersTitle")}
                                </h1>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("teachersFullDescription1")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("teachersFullDescription1Text")}
                                    </p>
                                </div>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("teachersFullDescription2")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("teachersFullDescription2Text")}
                                    </p>
                                </div>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("teachersFullDescription3")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("teachersFullDescription3Text")}
                                    </p>
                                </div>
                                <button
                                    style={{
                                        backgroundColor: "#03B4E6",
                                        fontSize: "16px",
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: "bold",

                                        borderRadius: "8px",
                                        color: "#FFF",
                                        border: "none",
                                        padding: "15px 30px",
                                    }}
                                >
                                    {t("learn")}
                                </button>
                            </div>
                        </div>

                        <div
                            style={applyResponsiveStyles(
                                styles.sectionContainer(true)
                            )}
                        >
                            <div
                                style={applyResponsiveStyles(
                                    styles.imageContainer
                                )}
                            >
                                <img
                                    src={parents}
                                    alt=""
                                    style={applyResponsiveStyles(styles.image)}
                                />
                            </div>
                            <div
                                style={applyResponsiveStyles(
                                    styles.textContainer
                                )}
                            >
                                <h1 style={applyResponsiveStyles(styles.title)}>
                                    {t("parentsTitle")}
                                </h1>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("parentsFullDescription1")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("parentsFullDescription1Text")}
                                    </p>
                                </div>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("parentsFullDescription2")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("parentsFullDescription2Text")}
                                    </p>
                                </div>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("parentsFullDescription3")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("parentsFullDescription3Text")}
                                    </p>
                                </div>
                                <button
                                    style={{
                                        backgroundColor: "#03B4E6",
                                        fontSize: "16px",
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: "bold",

                                        borderRadius: "8px",
                                        color: "#FFF",
                                        border: "none",
                                        padding: "15px 30px",
                                    }}
                                >
                                    {t("learn")}
                                </button>
                            </div>
                        </div>

                        <div
                            style={applyResponsiveStyles(
                                styles.sectionContainer(false)
                            )}
                        >
                            <div
                                style={applyResponsiveStyles(
                                    styles.imageContainer
                                )}
                            >
                                <img
                                    src={students}
                                    alt=""
                                    style={applyResponsiveStyles(styles.image)}
                                />
                            </div>
                            <div
                                style={applyResponsiveStyles(
                                    styles.textContainer
                                )}
                            >
                                <h1 style={applyResponsiveStyles(styles.title)}>
                                    {t("kidsTitle")}
                                </h1>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("kidsFullDescription1")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("kidsFullDescription1Text")}
                                    </p>
                                </div>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("kidsFullDescription2")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("kidsFullDescription2Text")}
                                    </p>
                                </div>
                                <div>
                                    <h2
                                        style={applyResponsiveStyles(
                                            styles.subtitle
                                        )}
                                    >
                                        {t("kidsFullDescription3")}
                                    </h2>
                                    <p
                                        style={applyResponsiveStyles(
                                            styles.description
                                        )}
                                    >
                                        {t("kidsFullDescription3Text")}
                                    </p>
                                </div>
                                <button
                                    style={{
                                        backgroundColor: "#03B4E6",
                                        fontSize: "16px",
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: "bold",

                                        borderRadius: "8px",
                                        color: "#FFF",
                                        border: "none",
                                        padding: "15px 30px",
                                    }}
                                >
                                    {t("learn")}
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <WhatWeDo /> */}
                    {/* <div className="why_join_us" id="why_join_us">
                        <div
                            className="contains2"
                            style={{ paddingBottom: "0", marginBottom: "0" }}
                        >
                            <Col className="justify-content-md">
                                <Row style={{ height: "33%" }}>
                                    <h2 className="heading">
                                        {t("whyJoinUs")}
                                    </h2>
                                </Row>
                                <Row style={{ height: "33%" }}></Row>
                                <Row style={{ height: "33%" }}>
                                    {t("whyJoinUsDescription")}
                                </Row>
                            </Col>
                        </div>
                    </div> */}
                    {/* <div
                        className="contains2"
                        style={{ width: "80%", marginBottom: 40 }}
                    >
                        <Container className="mt-4">
                            <Row className="gy-4">
                                {" "}
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("loveForLearningTitle")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("loveForLearningText")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("valuableLifeSkillsTitle")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("valuableLifeSkillsText")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("brighterFutureTitle")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("brighterFutureText")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("qualityFamilyTimeTitle")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("qualityFamilyTimeText")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("boostedConfidenceTitle")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("boostedConfidenceText")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Card.Text className="flex-grow-1">
                                    {t("familyNote")}
                                </Card.Text>
                            </Row>
                        </Container>
                    </div> */}

                    {/* <div className="about_us" id="about_us">
                        <div className="contains2">
                            <Col className="justify-content-md">
                                <Row style={{ height: "33%" }}>
                                    <h2 className="heading">
                                        <span>
                                            <img src={cube} className="cube" />
                                        </span>
                                        {t("aboutUs")}
                                    </h2>
                                </Row>
                                <Row style={{ height: "33%" }}></Row>
                                <Row style={{ height: "33%" }}>
                                    {t("aboutUsDescription")}
                                </Row>
                            </Col>
                        </div>
                    </div> */}

                    <div
                        style={{ marginTop: "6rem", padding: "15px" }}
                        id="about_us"
                    >
                        <div
                            style={{
                                width: "100%",
                                height: "auto",
                                padding: "50px 0",
                                backgroundColor: "#F3FAFF",
                            }}
                        >
                            <Container
                                style={{
                                    marginTop: "40px",
                                    marginBottom: "40px",
                                }}
                            >
                                <Row className="text-center">
                                    <Col>
                                        <h2
                                            style={{
                                                fontSize: "48px",
                                                fontWeight: "800",
                                                color: "#142B60",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                            }}
                                        >
                                            {t("aboutUs")}
                                        </h2>
                                        <p
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "300",
                                                color: "#142B60",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                                maxWidth: "700px",
                                                margin: "auto",
                                            }}
                                        >
                                            {t("aboutUsDescription")}
                                        </p>
                                    </Col>
                                </Row>

                                <Row
                                    className="text-center"
                                    style={{ marginTop: "5rem" }}
                                >
                                    <Col>
                                        <h2
                                            className="mb-4"
                                            style={{
                                                fontSize: "36px",
                                                fontWeight: "900",
                                                color: "#142b60",
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                            }}
                                        >
                                            {t("foundationsTitle")}
                                        </h2>
                                    </Col>
                                </Row>

                                <Row
                                    style={{ padding: "14px" }}
                                    className="g-5  "
                                >
                                    {[
                                        {
                                            imgSrc: industury,
                                            title: "foundation1Title",
                                            text: "foundation1Text",
                                        },
                                        {
                                            imgSrc: passion,
                                            title: "foundation2Title",
                                            text: "foundation2Text",
                                        },
                                        {
                                            imgSrc: mindset,
                                            title: "foundation3Title",
                                            text: "foundation3Text",
                                        },
                                        {
                                            imgSrc: school,
                                            title: "foundation4Title",
                                            text: "foundation4Text",
                                        },
                                        {
                                            imgSrc: commitment,
                                            title: "foundation5Title",
                                            text: "foundation5Text",
                                        },
                                    ].map(({ imgSrc, title, text }, index) => (
                                        <Col key={index} xs={12} md={6} lg={4}>
                                            <div
                                                className="d-flex flex-column"
                                                style={{
                                                    backgroundColor: "#FFFFFF",
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                    height: "100%",
                                                    boxShadow:
                                                        "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <img
                                                    src={imgSrc}
                                                    alt=""
                                                    style={{
                                                        width: "100%",
                                                        height: "230px",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                                <div
                                                    className="d-flex flex-column flex-grow-1"
                                                    style={{
                                                        padding: "20px",
                                                    }}
                                                >
                                                    <h2
                                                        style={{
                                                            fontSize: "24px",
                                                            fontWeight: "800",
                                                            color: "#142B60",
                                                            fontFamily:
                                                                "'Montserrat', sans-serif",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {t(title)}
                                                    </h2>
                                                    <p
                                                        style={{
                                                            fontSize: "16px",
                                                            fontWeight: "300",
                                                            color: "#142B60",
                                                            fontFamily:
                                                                "'Montserrat', sans-serif",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {t(text)}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </div>
                    </div>

                    {/* <div
                        className="contains2"
                        style={{ width: "80%", marginBottom: 40 }}
                    >
                        <Container className="mt-4">

                            <Row className="gy-4">
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("foundation1Title")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("foundation1Text")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("foundation2Title")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("foundation2Text")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("foundation3Title")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("foundation3Text")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("foundation4Title")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("foundation4Text")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>
                                                {t("foundation5Title")}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                                {t("foundation5Text")}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12}>
                                    <div className="h-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Text>
                                                {t("foundationsQuote")}
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}

                    <div className="footer">
                        <div
                            className="contains2 col-md-12"
                            style={{ maxWidth: "100%", width: "100%" }}
                        >
                            <Row className="justify-content-md-center">
                                <Col sm={3}>
                                    <img
                                        src={logo}
                                        className="image imf"
                                        style={{
                                            marginBottom: 20,
                                            width: "200px",
                                            height: "auto",
                                        }}
                                    />
                                </Col>
                                <Col sm={3}>
                                    <h5 className="contact-us">
                                        {t("contactUs")}
                                    </h5>
                                    <p className="footer-text">
                                        contact-us@futurestars.uk
                                    </p>
                                    <p className="footer-text">
                                        www.futurestars.uk
                                    </p>
                                </Col>
                                <Col sm={6} md={4}>
                                    <h5 className="contact-us">
                                        {t("addressTitle")}
                                    </h5>
                                    <p
                                        style={{ maxWidth: "250px" }}
                                        className="footer-text"
                                    >
                                        {t("address")}
                                    </p>
                                </Col>
                            </Row>
                            <hr className="footer-divider" />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <p
                                    className="footer-text"
                                    style={{
                                        textAlign: "center ",
                                        paddingTop: "30px",
                                    }}
                                >
                                    {t("footerPrompt")}
                                </p>
                                <div className="policy-container">
                                    <a
                                        style={{
                                            color: "white",
                                            marginRight: "20px",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => showCookiePolicy()}
                                    >
                                        {t("cookiePolicy")}
                                    </a>
                                    <a
                                        style={{
                                            color: "white",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => showPrivacyPolicy()}
                                    >
                                        {t("privacyPolicy")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Stack>

                <>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{modalHeading}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "60vh" }}>
                            {loading && (
                                <Spinner
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            )}
                            {showCookPolicy && (
                                <iframe
                                    src="https://app.termly.io/document/cookie-policy/215f780a-fb69-45a0-8c04-ebc1a78265e3"
                                    onLoad={() => showLoading(false)}
                                    style={{ width: "100%", height: "100%" }}
                                ></iframe>
                            )}
                            {showPrivPolicy && (
                                <iframe
                                    src="https://app.termly.io/document/privacy-policy/7761d1fe-45ba-46f7-9694-6e5505efc217"
                                    onLoad={() => showLoading(false)}
                                    style={{ width: "100%", height: "100%" }}
                                ></iframe>
                            )}
                            {showModalContent && modalContent}
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </>

                <>
                    <Modal
                        fullscreen={true}
                        show={showGame}
                        onHide={handleCloseGame}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Let's Play</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {loading && (
                                <Spinner
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            )}

                            <iframe
                                src={
                                    "https://dev.schoolcode.uk/demo.html?project_file=" +
                                    gameIds[gameSelected]
                                }
                                onLoad={() => showLoading(false)}
                                style={{ height: "100%", width: "100%" }}
                            ></iframe>
                        </Modal.Body>
                    </Modal>
                </>
            </div>
        </>
    );
}

// const appTarget = document.createElement("div");
// document.body.appendChild(appTarget);
// ReactDOM.render(<LandingPage />, appTarget);
